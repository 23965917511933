window.addActiveHeadItem = function() {
    let active_menu_id = 'about_menu_item';

    if ( window.location.pathname == '/' ) {
        active_menu_id = activeElementOnMainLanding();
    } else if ( window.location.pathname == '/help' ) {
        active_menu_id = 'help_menu_item';
    } else if ( window.location.pathname == '/8800' ) {
        active_menu_id = 'n8800_menu_item';
    } else return;


    $('.site-header-menu-item').removeClass('active');
    $(`#${active_menu_id}`).addClass('active');
};

function activeElementOnMainLanding() {
    let activeEl = {
                    price: 'price_menu_item',
                    about: 'about_menu_item'
                   };
    let localHash = window.location.hash;

    for (let el in activeEl) {
        if ( localHash && localHash === `#${el}`) return activeEl[el];
    }
}

$(document).ready(function(){
    $('a[data-reload="false"]').on('click', function(event) {
      if ( window.location.pathname == '/' || window.location.hash && window.location.hash === '#price' || window.location.hash && window.location.hash === '#about') {
        event.preventDefault();
        var hash = this.hash;
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800, function(){
          window.location.hash = hash;
          addActiveHeadItem();
        });
      }
    });
    
    $(".area-modal-wrapper").attr('id', 'sign_in');
    if ( window.location.hash && window.location.hash === '#sign_in' ) {
        login_modal_render();
    }
    check_list_city();

    if (document.documentElement.clientWidth <= 577) {
        set_event_for_video();
    }

    $(window).resize(function() {
        clearTimeout(check_timeout);
        check_timeout = setTimeout(function() {
            if (document.documentElement.clientWidth <= 577) {
                set_event_for_video();
            } else {
                $(".first-video-block").unbind('click');
            }

            window_width = $(window).outerWidth();
            if (window_width != old_window_width) {
                check_list_city();
                old_window_width = window_width;
            }
        }, 300);
    });

    function set_event_for_video() {
        $(".first-video-block").click(function() {
          $(this).css("width", "100%");
        });
        $(".video-preview").not('.purple-btn').click(function() {
          $(".first-video-block").attr("style","");
        });
    }

    $('.tariff-block').on("click", function (){
        if ($(this).hasClass('active')) { return; }

        $('.tariff-block.active').removeClass('active');
        $(this).addClass('active');
    });
    
    const map = document.querySelector('#map');
    if (map !== null) {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//api-maps.yandex.ru/2.1.76/?load=package.geoObjects&lang=ru_RU&apikey=d82e8cee-0528-4171-a141-db9321388f84';
        map.appendChild(script);
        script.onload = script.onreadystatechange = function() {
            if ((!this.readyState || this.readyState == "loaded" || this.readyState == "complete")){
                setupYandexMap();
            }
        };
    }

    $('body').on('click', '.baloon-btn', function(e){
      let town = $(e.target).parents('ymaps').find('.baloon-title').text();
      let date = new Date();
      date.setMinutes(date.getMinutes() + 10);
      let updatedCookie = "map_select_town=" + encodeURIComponent(town) + "; path=/; ";
      updatedCookie += "expires=" + date.toUTCString() + "; ";
      updatedCookie += "domain=." + document.domain + ";";
      document.cookie = updatedCookie;
    });
});

window.login_modal_render = function(wizard_key) {
    $(".area-modal-wrapper .inner-content-wrapper").html(
        `${modal_link}`
    );
    modal_show();
    $("a.singup-social-item").attr('data-wizard', wizard_key);
    $("a.singup-social-item").on('click', function(e){
      let wizard = (e.currentTarget || e.target).dataset.wizard;
      if (!wizard) return;
      let updatedCookie = "new_wizard_key=" + encodeURIComponent(wizard) + "; path=/; ";
      updatedCookie += "domain=." + document.domain + ";";
      document.cookie = updatedCookie;
    });
}

window.setupYandexMap = function(){
    if (typeof ymaps === 'undefined') { return; }

    ymaps.ready(function () {
        initializeMap([20, 55]);
    });

    function initializeMap(center) {
        let map = new ymaps.Map($('#map')[0], {
            center: center,
            mapStateAutoApply: true,
            zoom: 5,
            controls: [],
        }, {
            suppressMapOpenBlock: true,
            autoPan: true
        });
        let clusterer = new ymaps.Clusterer({
            clusterIconLayout: 'default#pieChart',
            iconPieChartCoreRadius: 20,
            clusterHideIconOnBalloonOpen: false,
            geoObjectHideIconOnBalloonOpen: false,
        });

        map.controls.add('zoomControl', {position: {top: '207px', right: '28px'}});
        map.behaviors.disable("scrollZoom");
        clusterer.add(collectMapPoint());
        map.geoObjects.add(clusterer);
        map.setBounds(clusterer.getBounds(), {
            checkZoomRange: true
        });
    }

    function collectMapPoint() {
        let geoObjects = [];
        for (let [key, value] of Object.entries(window.map_poins)) {
            geoObjects.push(
                new ymaps.Placemark(
                    value["location"], {
                        balloonContent: `<p class="baloon-title">${key}</p><p class="baloon-desc">Есть свободные номера</p>${baloon_btn_modal}`
                    }, {
                        preset: value["country"] === "ru" ? 'islands#greenCircleDotIcon' : 'islands#blueCircleIcon',
                        iconColor: value["country"] === "ru" ? '#0FDDA0' : '#00AFCA',
                        hideIconOnBalloonOpen: true,
                        balloonPanelMaxMapArea: 0,
                    },
                ),
            );
        }

        return geoObjects;
    }
}

window.player = [];
window.played = null;
window.click_play = false;

window.load_video = function(indexOfVideo, videoId) {
  if ( window.innerWidth <= 577 ) {
    if(played !== null){
      $(`#prev${played}`).show();
      $(`#video${played}`).hide();
      player[played].pauseVideo();
    }
    played = indexOfVideo;
    $(`#prev${played}`).hide();
    $(`#video${played}`).show();
    $(window).scrollTop( $(`#video${played}`).offset().top - window.innerHeight / 2 + $(`#video${played}`).innerHeight() / 2);
    player[played].playVideo();
  } else { 
    var player_video = $('.landing-main-wrapper .area-modal-wrapper .main-modal-wrapper .inner-content-wrapper');
    var iframe = document.createElement( "iframe" );
    iframe.setAttribute( "height", "100%" );
    iframe.setAttribute( "width", "100%");
    iframe.setAttribute( "frameborder", "0" );
    iframe.setAttribute( "allow", "autoplay; encrypted-media; fullscreen; picture-in-picture" );
    iframe.setAttribute( "allowfullscreen", "" );
    iframe.setAttribute( "src", `https://vkvideo.ru/video_ext.php?oid=-196609305&id=${videoId}&autoplay=1` );
    player_video.innerHTML = "";
    player_video.append( iframe );
    $(".area-modal-wrapper").addClass("video-modal");
    modal_show();
  }
};

function onYouTubePlayerAPIReady() {
  initializePlayers();
}

function initializePlayers() {
  document.querySelectorAll(".video-preview").forEach(function (el) {
    const index = Number(el.getAttribute("id").replace("prev", ""));
    player[index] = new YT.Player(`video${index}`, {
      width: "100%",
      height: "100%",
      videoId: el.getAttribute("data-video-id"),
    });
  });
}

var check_timeout, window_width;
var old_window_width = $(window).outerWidth();

function check_list_city() {
    let check_block = $('div').is('.list-city');
    
    if (check_block) {
        append_big_letter_into_cities_list();
    }
}

function append_big_letter_into_cities_list() {
    let list_top = $('.list-city').offset().top;

    $('.appended-letter').remove();
    $('.list-city .name-city').removeClass("city-margin-bottom");

    $('.list-city .name-city').each(function() {
        if($(this).offset().top == list_top) {
            let div = $('<p class="letter-city appended-letter" />');
            let letter = $(this).data("letter");

            if ($(this).prev().hasClass('letter-city')) {
                $(this).prev().css('visibility','hidden');
            }

            div.text(letter);
            div.insertBefore(this);
        }
        if ($(this).next().hasClass('letter-city')) {
            $(this).addClass("city-margin-bottom");
        }
    });
}
